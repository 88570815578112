import Vue from 'vue';
import EventBus from '@shared/services/EventBus';

const d = document;
const s = d.createElement('script');

s.src = 'https://client.crisp.chat/l.js';
s.async = true;
d.getElementsByTagName('head')[0].appendChild(s);

window.$crisp = [];
window.CRISP_WEBSITE_ID = 'aeb1b92a-53dd-47fb-87b7-7780cf7970a1';
window.CRISP_READY_TRIGGER = function() {
  const a = d.querySelector('a[href^="https://crisp"]');
  a.innerHTML = a.innerHTML.replace('We run on', 'Créé avec');
};

Vue.prototype.$crisp = {
  open() {
    window.$crisp.push(['do', 'chat:open']);
  },
};

EventBus.on('authchange', (user) => {
  if (user) {
    window.$crisp.push(['set', 'user:email', user.email]);
    user.store && window.$crisp.push(['set', 'session:data', [[['plan', user.store.plan]]]]);
  } else {
    window.$crisp.push(['do', 'session:reset']);
  }
});
