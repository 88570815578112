<script>
import Logo from '@app/components/Logo.vue';
import UserNotificationList from '@app/components/UserNotificationList.vue';

export default {
  components: {
    Logo,
    UserNotificationList,
  },
  props: {
    onNavigate: {
      type: Function,
      default: () => (() => {}),
    },
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    canSeePlan() {
      return (
        !this.$store.getters['auth/enterpriseCapabilities'].hide_plan
        && !['TEACHER', 'ANALYST'].includes(this.authUser.role)
      );
    },
  },
};
</script>

<template>
  <header class="ahcomp pl-1 pr-2 has-background-white">
    <nav class="ahcomp_nav level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <slot name="leftBtn" />
        </div>
        <div class="level-item">
          <Logo :onNavigate="onNavigate" />
        </div>
      </div>

      <div class="ahcomp_nav level-right">
        <div class="ahcomp_nav_item level-item">
          <b-tooltip label="Voir l'espace apprenant" position="is-bottom">
            <b-button
              type="is-text"
              size="is-medium"
              tag="a"
              :href="$store.getters['auth/url']"
              target="_blank"
              icon-pack="far"
              icon-left="eye"
            />
          </b-tooltip>
        </div>
        <div class="ahcomp_nav_item level-item">
          <UserNotificationList />
        </div>
        <div class="ahcomp_nav_item level-item">
          <b-dropdown ref="menuHelp" aria-role="menu" position="is-bottom-left">
            <template #trigger>
              <b-tooltip label="Aide" position="is-bottom">
                <b-button
                  type="is-text"
                  size="is-medium"
                  icon-pack="far"
                  icon-left="question-circle"
                />
              </b-tooltip>
            </template>
            <b-dropdown-item arial-role="menuitem" @click="$crisp.open()">
              <b-icon class="valign-middle" size="is-medium" icon="envelope" />
              <span class="valign-middle">Contacter le support</span>
            </b-dropdown-item>
            <b-dropdown-item arial-role="menuitem" has-link>
              <a
                :href="$constants.YT_URL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b-icon class="valign-middle" size="is-medium" icon="book-reader" />
                <span class="valign-middle">Nos Tutoriels</span>
              </a>
            </b-dropdown-item>
            <b-dropdown-item arial-role="menuitem" has-link>
              <a
                href="https://aide.teachizy.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b-icon class="valign-middle" size="is-medium" icon="life-ring" />
                <span class="valign-middle">Notre Centre d'Aide</span>
              </a>
            </b-dropdown-item>
            <b-dropdown-item arial-role="menuitem" has-link>
              <a
                href="https://www.meetup.com/fr-FR/Vivre-dun-business-en-ligne-formateurs-infopreneurs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b-icon class="valign-middle" pack="fab" size="is-medium" icon="meetup" />
                <span class="valign-middle">Nos Meetups</span>
              </a>
            </b-dropdown-item>
            <b-dropdown-item arial-role="menuitem" has-link>
              <a
                href="https://www.facebook.com/groups/690041678426805"
                target="_blank"
                rel="noreferrer noopener"
              >
                <b-icon
                  class="valign-middle"
                  size="is-medium"
                  icon="facebook"
                  pack="fab"
                />
                <span class="valign-middle">La Communauté</span>
              </a>
            </b-dropdown-item>
            <b-dropdown-item arial-role="menuitem" has-link>
              <a
                  href="https://academie.teachizy.fr/"
                  target="_blank"
                  rel="noreferrer noopener"
              >
                <b-icon class="valign-middle" size="is-medium" icon="graduation-cap" />
                <span class="valign-middle">Notre Académie</span>
              </a>
            </b-dropdown-item>
            <b-dropdown-item arial-role="menuitem" has-link>
              <a
                href="https://www.teachizy.fr/blog/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <b-icon
                  class="valign-middle"
                  pack="fab"
                  size="is-medium"
                  icon="wordpress"
                />
                <span class="valign-middle">Notre Blog</span>
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="ahcomp_nav_item level-item">
          <b-dropdown ref="menuUser" aria-role="menu" position="is-bottom-left">
            <template #trigger>
              <b-button type="is-text">
                <b-icon
                  class="valign-middle"
                  size="is-medium"
                  pack="far"
                  icon="user-circle"
                />
                <span class="is-hidden-touch valign-middle">
                  {{ authUser.firstname }}
                </span>
                <b-icon
                  class="is-hidden-touch valign-middle"
                  size="is-small"
                  icon="chevron-down"
                />
              </b-button>
            </template>
            <b-dropdown-item arial-role="menuitem" has-link>
              <router-link :to="{name: 'account'}" active-class="is-active">
                <b-icon
                  class="valign-middle"
                  size="is-medium"
                  icon="user-circle"
                />
                <span class="valign-middle">Mon compte</span>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canSeePlan"
              arial-role="menuitem"
              has-link
            >
              <router-link
                :to="{
                  name: 'plan',
                  query: $store.getters['auth/isNotPercentPlan']
                    ? {onglet: 'vous' } : {}
                }"
                active-class="is-active"
              >
                <b-icon
                  class="valign-middle"
                  size="is-medium"
                  icon="gem"
                />
                <span class="valign-middle">Abonnement</span>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item arial-role="menuitem" has-link>
              <a :href="$store.getters['auth/url']" target="_blank">
                <b-icon
                  class="valign-middle"
                  size="is-medium"
                  icon="globe"
                />
                <span class="valign-middle">Espace apprenant</span>
              </a>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$store.getters['auth/isRoleOwner']"
              arial-role="menuitem"
              has-link
            >
              <router-link :to="{name: 'affiliation'}" active-class="is-active">
                <b-icon class="valign-middle" size="is-medium" icon="donate" />
                <span class="valign-middle">Programme affilié</span>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              arial-role="menuitem"
              @click="$store.dispatch('auth/logout')"
            >
              <b-icon
                class="valign-middle"
                size="is-medium"
                icon="sign-out-alt"
              />
              <span class="valign-middle">Déconnexion</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
.ahcomp {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  box-shadow: $box-shadow;

  .level {
    height: 100%;
  }

  @include touch {
    &_nav.level {
      .level-item {
        margin-right: pxToRem(12px);
      }
    }
  }
}
</style>
